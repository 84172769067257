export default (data) => {
  if (data?.flaechen?.length === 0 || !data?.flaechen?.grundstuecksflaeche) {
    data.flaechen = {
      ...data?.flaechen,
      ...(data?.eckdaten?.grundstueck &&
        data?.eckdaten?.grundstueck !== "-" && {
          grundstuecksflaeche: data?.eckdaten?.grundstueck,
        }),
    };
  }
  return data?.flaechen;
};

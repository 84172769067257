import React, { useEffect, useState } from "react";

const Snowflake = (props) => {
  return (
    <p className="Snowflake" id={`item${props.id}`} style={props.style}>
      *
    </p>
  );
};

export const LazySnowfall = () => {
  const [snowflakes, setSnowflakes] = useState([]);

  const addSnowflakes = () => {
    const additionalSnowflakes = 100;

    setSnowflakes((prevSnowflakes) => [
      ...prevSnowflakes,
      ...Array.from({ length: additionalSnowflakes }, (_, i) => {
        let style = {
          animationDelay: `-${(Math.random() * 35).toFixed(2)}s`,
          fontSize: `${Math.floor(Math.random() * 10) + 10}px`,
          top: "-10%",
          left: `${Math.random() * 100}vw`,
        };
        return (
          <Snowflake
            key={prevSnowflakes.length + i}
            id={prevSnowflakes.length + i}
            style={style}
          />
        );
      }),
    ]);
  };

  useEffect(() => {
    addSnowflakes();
  }, []);

  return <div className="SnowfallContainer">{snowflakes}</div>;
};

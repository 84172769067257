import React from "react";
import { List, ListItem, Grid, Typography, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import formatNumberToCurrency from "../../utils/formatNumberToCurrency";

const useStyles = makeStyles((theme) => ({
  gridItem: {
    display: "flex",
    alignItems: "center",
  },
  label: {
    textAlign: "right",
    width: "100%",
    paddingRight: theme.spacing(2),
    hyphens: "auto",
    hyphenateCharacter: "-",
  },
  headerDivider: {
    height: 2,
  },
}));

const KeyValueList = ({ title, items, data, hideDivider = false }) => {
  const classes = useStyles();

  if (data?.bodenbelag?.toLowerCase() === "kunststoff") {
    data.bodenbelag = "Vinyl";
  }

  if (data?.terrassen && parseInt(data?.terrassen) > 0) {
    data.terrassen = "Ja";
  }

  return (
    <List subheader={<Typography variant="body1">{title}</Typography>}>
      {!hideDivider ? <Divider className={classes.headerDivider} /> : null}
      {items
        .filter((item) => data[item.key] !== "-")
        .map(({ key, label, postfix }, index) => {
          const formattedData =
            data[key] && key === "kaution"
              ? formatNumberToCurrency(data[key])
              : data[key];

          if(!formattedData) return null;

          return (
            <React.Fragment key={index}>
              <ListItem>
                <Grid container>
                  <Grid item xs={6} md={5} className={classes.gridItem}>
                    <Typography
                      variant="body1"
                      align="right"
                      component="div"
                      color="textSecondary"
                      className={classes.label}
                    >
                      {label}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={7} className={classes.gridItem}>
                    <Typography variant="body1">
                      {formattedData}
                      {postfix}
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
              <Divider />
            </React.Fragment>
          );
        })}
    </List>
  );
};

export default KeyValueList;

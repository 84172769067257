import React, { useState } from "react";
import { Container, Typography, Button, Box, Chip } from "@material-ui/core";
import Separator from "../layout/Separator";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import TeamWeichImg from "../../images/team2022_weich.jpg";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import AngeboteSlider from "../offers/OffersSlider";
import ArchivedSlider from "../offers/ArchivedSlider";
import CancelIcon from "@material-ui/icons/Cancel";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";

import holidayGreetingImage from "../../images/wepik-photo-mode-20221025-225713-min.png";
import CustomDialog from "../global/CustomDialog";
import { useDispatch } from "react-redux";
import { setHolidayGreeting } from "../../store/actions/settingsActions";
import ImmobilienBewertungTeaser from "../global/ImmobilienBewertungTeaser";
import ReviewIcons from "../review-icons/ReviewIcons";
import VideoCreationTeaser from "../global/VideoCreationTeaser";
import "../../App.css";
import WhatsAppTeaser from "../global/WhatsAppTeaser";
import DeclutteringTeaser from "../global/DeclutteringTeaser";
import { LazySnowfall } from "../global/LazySnowfall";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  slider: {
    marginTop: 0,
    padding: theme.spacing(3, 1),
    background: "url(" + TeamWeichImg + ") no-repeat center bottom",
    backgroundSize: "cover",
    backgroundAttachment: "fixed",
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(6, 0),
    },
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
      padding: theme.spacing(4),
    },
  },
  section1: {
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(8),
    },
  },
  sliderArchived: {
    marginTop: 0,
  },
  sliderTitle: {
    color: "white",
    marginBottom: 0,
  },
  cta: {
    marginTop: theme.spacing(4),
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(4),
    },
  },
  claim: {
    display: "flex",
    alignItems: "flex-start",
    paddingTop: theme.spacing(2),
    height: "53vw",
    [theme.breakpoints.down("sm")]: {
      height: "53vw",
      paddingTop: "18px",
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(2),
      textShadow: "1px 1px 2px rgba(0,0,0,0.5)",
    },
    justifyContent: "space-between",
  },
  socialIconBtn: {
    color: "#fff",
    opacity: 0.8,
    width: 100,
    height: 100,
    margin: theme.spacing(0, 3),
    "&:hover": {
      opacity: 1,
      background: "rgba(255,255,255,0.1)",
    },
    [theme.breakpoints.down("xs")]: {
      width: 50,
      height: 50,
      margin: theme.spacing(0, 1),
    },
  },
  socialIcon: {
    width: 50,
    height: 50,
    [theme.breakpoints.down("xs")]: {
      width: 25,
      height: 25,
    },
  },
  provenExpertLogo: {
    border: 0,
    width: 135,
    height: 135,
    [theme.breakpoints.down("sm")]: {
      width: 110,
      height: 110,
    },
    [theme.breakpoints.down("xs")]: {
      width: 50,
      height: 50,
    },
  },
  provenExpertLogoFixed: {
    border: 0,
    width: 135,
    height: 135,
    position: "fixed",
    right: 0,
    bottom: theme.spacing(15),
    zIndex: 999,
    [theme.breakpoints.down("sm")]: {
      width: 110,
      height: 110,
    },
    [theme.breakpoints.down("xs")]: {
      width: 110,
      height: 110,
    },
  },
  provenExpertLogoFixedHeader: {
    border: 0,
    width: 135,
    position: "fixed",
    right: 0,
    bottom: "255px",
    zIndex: 999,
    height: "20px",
    display: "flex",
    justifyContent: "flex-end",
    backgroundColor: "white",
    [theme.breakpoints.down("sm")]: {
      width: 110,
      bottom: 230,
    },
    [theme.breakpoints.down("xs")]: {
      width: 110,
      bottom: 230,
    },
  },
  advancedProvenExpertLogo: {
    position: "fixed",
    right: 0,
    height: 25,
    display: "flex",
    justifyContent: "flex-end",
    zIndex: 9999,
    bottom: theme.spacing(20),
    backgroundColor: "white",
    width: "465px",
    alignItems: "center",
  },
  reviews: {
    maxWidth: "900px",
    margin: "0px auto",
    gap: 20,
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "center",
      gap: theme.spacing(1),
    },
  },
  reviewBox: {
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    textAlign: "center",
    display: "flex",
    backgroundColor: "#fff",
    borderRadius: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(2),
      width: 220,
    },
  },
  holidayGreetingImage: {
    border: `2px solid ${theme.palette.text.main}`,
    borderRadius: theme.spacing(1.5),
  },
  cancelIcon: {
    position: "absolute",
    right: theme.spacing(1.5),
    top: theme.spacing(1.5),
    cursor: "pointer",
    "& svg": {
      color: "#fff",
    },
  },
  title: {
    color: "#fff",
  },
  navigateBtn: {
    fontWeight: "bold",
  },
}));

const Home = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const websiteSettings = useSelector((state) => state.settings);
  const [disableProvenExpertLogo, setDisableProvenExpertLogo] = useState(false);
  const [isOpenHolidayGreeting, setIsOpenHolidayGreeting] = useState(true);

  const renderProvenExpertLogo = (position) => (
    <>
      <a
        href="https://www.provenexpert.com/marquart-immobilien/?utm_source=Widget&amp;utm_medium=Widget&amp;utm_campaign=Widget"
        title="Kundenbewertungen &amp; Erfahrungen zu MARQUART IMMOBILIEN. Mehr Infos anzeigen."
        target="_blank"
        style={{
          textDecoration: "none",
          ...(position === "fixed" && { visibility: "hidden" }),
        }}
        rel="noopener noreferrer"
        className={position ? "provenExpertLogoFixed" : "provenExpertLogo"}
        id={position ? "provenExpertLogoFixedId" : "provenExpertLogoId"}
        onClick={(e) => {
          if (position === "fixed") {
            if (window.innerWidth > 768) {
              e.preventDefault();
              hideFixedProvenExpertLogo();
              showAdvancedFixedProvenExpertLogo();
            }
          }
        }}
      >
        {position === "fixed" && (
          <div
            className={classes.provenExpertLogoFixedHeader}
            onClick={(e) => {
              e.preventDefault();
              hideFixedProvenExpertLogo();
              setDisableProvenExpertLogo(true);
            }}
          >
            <CancelIcon fontSize="small" color="primary" />
          </div>
        )}

        <img
          src="https://images.provenexpert.com/22/95/03895b89ba09bba8287e36090bf1/widget_square_180_de_0.png"
          alt="Erfahrungen &amp; Bewertungen zu MARQUART IMMOBILIEN"
          className={
            position === "fixed"
              ? classes.provenExpertLogoFixed
              : classes.provenExpertLogo
          }
        />
      </a>
    </>
  );

  const renderAdvancedProvenExpertLogo = () => (
    <div id="advancedProvenExpertLogo" style={{ visibility: "hidden" }}>
      <div
        className={classes.advancedProvenExpertLogo}
        style={{ bottom: "289px" }}
        onClick={(e) => {
          e.preventDefault();
          hideAdvancedFixedProvenExpertLogo();
          showFixedProvenExpertLogo();
        }}
      >
        <Chip
          variant="outlined"
          size="small"
          avatar={<ArrowBackRoundedIcon fontSize="small" />}
          label="Zurück"
          onClick={(e) => {
            e.preventDefault();
            hideAdvancedFixedProvenExpertLogo();
            showFixedProvenExpertLogo();
          }}
        />
      </div>

      <a
        href="https://www.provenexpert.com/marquart-immobilien/?utm_source=Widget&utm_medium=Widget&utm_campaign=Widget"
        title="Kundenbewertungen &amp; Erfahrungen zu MARQUART IMMOBILIEN. Mehr Infos anzeigen."
        target="_blank"
        rel="noopener noreferrer"
        className={classes.advancedProvenExpertLogo}
        // style={{ maxHeight: 150 }}
      >
        <img
          src="https://images.provenexpert.com/22/95/03895b89ba09bba8287e36090bf1/widget_recommendation_465_0.png?t=1651964092174"
          alt="Kundenbewertungen &amp; Erfahrungen zu MARQUART IMMOBILIEN. Mehr Infos anzeigen."
          style={{ border: 0 }}
        />
      </a>
    </div>
  );

  const isInViewport = (element) => {
    if (!element) return;
    const rect = element?.getBoundingClientRect();

    if (rect?.top && rect?.bottom && rect?.left) {
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      );
    }
  };

  const showFixedProvenExpertLogo = () => {
    if (document.getElementById("provenExpertLogoFixedId")) {
      document.getElementById("provenExpertLogoFixedId").style.visibility =
        "visible";
    }
  };

  const hideFixedProvenExpertLogo = () => {
    if (document.getElementById("provenExpertLogoFixedId")) {
      document.getElementById("provenExpertLogoFixedId").style.visibility =
        "hidden";
    }
  };

  const showAdvancedFixedProvenExpertLogo = () => {
    if (document.getElementById("advancedProvenExpertLogo")) {
      document.getElementById("advancedProvenExpertLogo").style.visibility =
        "visible";
    }
  };

  const hideAdvancedFixedProvenExpertLogo = () => {
    if (document.getElementById("advancedProvenExpertLogo")) {
      document.getElementById("advancedProvenExpertLogo").style.visibility =
        "hidden";
    }
  };

  const isAdvancedFixedProvenExpertLogoVisible = () => {
    if (document.getElementById("advancedProvenExpertLogo")) {
      return window.getComputedStyle(
        document.getElementById("advancedProvenExpertLogo")
      ).visibility;
    }

    return "hidden";
  };

  React.useEffect(() => {
    document.addEventListener(
      "scroll",
      function () {
        if (isInViewport(document.querySelector(".provenExpertLogo"))) {
          if (!disableProvenExpertLogo) {
            hideFixedProvenExpertLogo();
            hideAdvancedFixedProvenExpertLogo();
          }
        } else {
          if (!disableProvenExpertLogo) {
            let result = isAdvancedFixedProvenExpertLogoVisible();
            if (result === "visible") return;
            showFixedProvenExpertLogo();
          }
        }
      },
      {
        passive: true,
      }
    );
    // eslint-disable-next-line
  }, []);

  const handleCloseHolidayGreeting = () => {
    dispatch(setHolidayGreeting());
    setIsOpenHolidayGreeting(false);
  };

  const renderHolidayGreeting = () => (
    <CustomDialog
      title={null}
      handleClose={handleCloseHolidayGreeting}
      isOpen={isOpenHolidayGreeting}
    >
      <Box className={classes.cancelIcon} onClick={handleCloseHolidayGreeting}>
        <CancelIcon fontSize="medium" />
      </Box>
      <img src={holidayGreetingImage} alt="holiday-greeting" />
    </CustomDialog>
  );

  return (
    <>
      <Container maxWidth="lg" className={classes.claim}>
        <Box>
          <Typography variant="h3" className={classes.title}>
            Ihr Immobilienmakler
          </Typography>
          <Typography variant="h3" className={classes.title}>
            aus Bad Kissingen
          </Typography>
        </Box>
        {renderProvenExpertLogo()}
      </Container>
      {!disableProvenExpertLogo && renderProvenExpertLogo("fixed")}
      {!disableProvenExpertLogo && renderAdvancedProvenExpertLogo()}
      {renderHolidayGreeting()}
      <LazySnowfall />
      <div className={clsx(classes.slider, classes.sliderArchived)}>
        <ImmobilienBewertungTeaser isDarkMode isHome />
      </div>
      <div className={clsx(classes.slider, classes.section1)}>
        <Container maxWidth="lg">
          <Typography
            variant="h3"
            align="center"
            className={classes.sliderTitle}
          >
            Unsere Top-Immobilien
          </Typography>
          <Separator dense />
          <AngeboteSlider />
          <div className={classes.cta}>
            <Button
              variant="contained"
              color="secondary"
              component={Link}
              to="/immobilien/angebote"
              size="small"
              className={classes.navigateBtn}
            >
              Alle Immobilien anzeigen
            </Button>
          </div>
        </Container>
      </div>
      <div className={clsx(classes.slider, classes.sliderArchived)}>
        <WhatsAppTeaser />
      </div>
      <div className={clsx(classes.slider, classes.sliderArchived)}>
        <Container maxWidth="lg">
          <Typography
            variant="h3"
            align="center"
            className={classes.sliderTitle}
          >
            Unsere neuesten Referenzen
          </Typography>
          <Separator dense />
          <ArchivedSlider />
          <div className={classes.cta}>
            <Button
              variant="contained"
              color="secondary"
              component={Link}
              to="/immobilien/verkauft"
              size="small"
              className={classes.navigateBtn}
            >
              Alle Referenzen anzeigen
            </Button>
          </div>
        </Container>
      </div>
      <div className={clsx(classes.slider, classes.sliderArchived)}>
        <DeclutteringTeaser isDarkMode isHome />
      </div>
      <div className={clsx(classes.slider, classes.sliderArchived)}>
        <Container maxWidth="lg">
          <Typography
            variant="h3"
            align="center"
            className={classes.sliderTitle}
          >
            Rezensionen
          </Typography>
          <Separator dense />
          <ReviewIcons />

          <div className={classes.cta}>
            <Button
              variant="contained"
              color="secondary"
              component={Link}
              to="/bewertungen"
              size="small"
              className={classes.navigateBtn}
            >
              Alle Rezensionen anzeigen
            </Button>
          </div>
        </Container>
      </div>
      <div className={clsx(classes.slider, classes.sliderArchived)}>
        <VideoCreationTeaser isDarkMode isHome />
      </div>
      <Helmet>
        <title>{websiteSettings.title}</title>
        <meta name="description" content={websiteSettings.description} />
      </Helmet>
    </>
  );
};

export default Home;
